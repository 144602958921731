<template>
  <div>
    <div class="wet-section">
      <div class="container">
        <div class="d-flex justify-content-center">
          <div v-if="partnerLogo.url" class="mb-3" style="max-width:520px;">
            <wp-picture :image="partnerLogo" />
          </div>
        </div>
        <h3 v-if="partner.show_name" class="empty-ph is-size-6">
          {{stringsAdditional.agent_title}}
        </h3>
        <div v-if="partner.show_name" class="mt-n3 mb-3">{{ partner.name }}</div>
        <div
          v-if="partner.show_name"
          class="nice-select mb-3"
          id="agent-select"
          :class="agentSelectOpen ? 'open' : ''"
          @click="agentSelectOpen = !agentSelectOpen"
          style="min-width:auto;"
        >
          <span class="current">
            {{form.agent.name}}
          </span>
          <label>
            {{stringsAdditional.agent_plh}}
          </label>
          <ul class="list">
            <li
              class="option"
              :id="`agent-option-${id}`"
              :class="form.agent.id === item.id ? 'selected focus' : ''"
              v-for="(item, id) in agents"
              :key="id"
              @click="$set(form, 'agent', item)"
            >{{item.name}}</li>
          </ul>
        </div>
        <h3 class="empty-ph is-size-6">
          {{stringsAdditional.company_name_title}}
        </h3>
        <div
          class="wet-input wet-input-with-label"
          :class="$v.form.companyName.$error ? 'wet-input-error' : ''"
        >
          <input
            id="wet-input-company"
            type="text"
            :placeholder="stringsAdditional.company_name_plh"
            v-model.trim="$v.form.companyName.$model"
          >
          <label for="wet-input-company">
            {{ stringsAdditional.company_name_plh }}
          </label>
        </div>
        <div v-if="$v.form.companyName.$error" class="small text-primary mt-n2 mb-3 mx-3">
          {{ stringsAdditional.error_company }}
        </div>
        <h3 class="empty-ph is-size-6 mt-4 mt-sm-5">
          {{stringsAdditional.addr_title}}
        </h3>
        <div
          class="wet-input wet-input-with-label has-addons"
          @click="addrShow = !addrShow"
        >
          <wet-icon class="wet-icon text-secondary" type="map" />
          <input
            id="wet-input-selected-address"
            disabled
            type="text"
            :value="selectedAddress"
          >
          <label for="wet-input-selected-address">
            {{strings.commonApp && strings.commonApp.selected_addr_label}}
          </label>
        </div>
        <label
          id="wet-additional-reg-addr-chkbx-label"
          class="checkbox is-size-7 mb-2"
          :class="{
            'disabled': isNoStreet || isTappedLocation,
          }"
          for="wet-additional-reg-addr-chkbx"
        >
          <strong class="empty-ph">
            {{stringsAdditional.reg_addr_chkbx}}
          </strong>
          <input
            type="checkbox"
            id="wet-additional-reg-addr-chkbx"
            :disabled="isNoStreet || isTappedLocation"
            v-model="form.regAddrChkbx"
          >
          <span id="wet-additional-reg-addr-chkbx-span"></span>
        </label>
        <transition name="fade">
          <div v-if="form.regAddrChkbx">
            <places-text
              ref="registration"
              @change-address="updateRegistrationAddress"
              @change-invalid-value="updateRegistrationInvalid"
              @change-invalid-street-value="updateRegistrationInvalidStreet"
              @change-house-no-value="updateHouseNo"
              @change-zip-value="updateZip"
              @change-city-value="updateCity"
              :address="formApi.registration"
              :selectedAddress="form.registration"
              class="my-3"
              :initial-address="initialAddress"
              :address-error-text="stringsAdditional.error_registraion_address"
              :address-placeholder="stringsAdditional.str_and_house_plh"
              :zip-placeholder="stringsAdditional.zip_code_plh"
              :city-placeholder="stringsAdditional.city_plh"
              :country-placeholder="stringsAdditional.country_plh"
              :fulladdress="true"
              :searchTypes="['address']"
              :searchCountries="searchCountries"
              :street-placeholder="stringsAdditional.str_plh"
              :house-no-error-text="stringsAdditional.error_house_no"
              :city-error-text="stringsAdditional.error_city"
              :zip-error-text="stringsAdditional.error_zip"
              :house-no-placeholder="stringsAdditional.house_no_plh"
            />
          </div>
        </transition>
        <h3 class="empty-ph is-size-6 mt-4 mt-sm-5">
          {{stringsAdditional.persnl_i_title}}
        </h3>
        <div class="row no-gutters">
          <div class="col-12 col-sm-6 pr-0 pr-sm-2">
            <div
              class="wet-input wet-input-with-label"
              :class="$v.form.firstname.$error ? 'wet-input-error' : ''"
            >
              <input
                id="wet-input-firstname"
                type="text"
                :placeholder="stringsAdditional.first_name_plh"
                v-model.trim="$v.form.firstname.$model"
              >
              <label for="wet-input-firstname">
                {{ stringsAdditional.first_name_plh }}
              </label>
            </div>
            <div v-if="$v.form.firstname.$error" class="small text-primary mt-n2 mb-3 mx-3">
              {{ stringsAdditional.error_username }}
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0 pl-sm-2">
            <div
              class="wet-input wet-input-with-label"
              :class="$v.form.lastname.$error ? 'wet-input-error' : ''"
            >
              <input
                id="wet-input-lastname"
                type="text"
                :placeholder="stringsAdditional.last_name_plh"
                v-model.trim="$v.form.lastname.$model"
              >
              <label for="wet-input-lastname">
                {{ stringsAdditional.last_name_plh }}
              </label>
            </div>
            <div v-if="$v.form.lastname.$error" class="small text-primary mt-n2 mb-3 mx-3">
              {{ stringsAdditional.error_username }}
            </div>
          </div>
        </div>
        <div class="form-grouped d-flex">
          <div
            class="nice-select"
            id="phone-code-select"
            :class="codeSelectOpen ? 'open' : ''"
            @click="codeSelectOpen = !codeSelectOpen"
            style="min-width:auto;"
          >
            <span class="current">
              {{(phoneCodes.filter((c) => c.code === form.phoneCode)[0] || {}).text}}
            </span>
            <ul class="list">
              <li
                class="option"
                :id="`phone-code-option-${id}`"
                :class="(form.phoneCode || '').toString() === item.code ? 'selected focus' : ''"
                v-for="(item, id) in phoneCodes"
                :key="id"
                @click="$set(form, 'phoneCode', item.code)"
                :title="item.country"
              >{{item.text}}</li>
            </ul>
          </div>
          <div
            class="wet-input wet-input-with-label w-100"
            :class="$v.form.phone.$error ? 'wet-input-error' : ''"
          >
            <input
              id="wet-input-phone"
              type="tel"
              inputmode="tel"
              :placeholder="stringsAdditional.phone_plh"
              v-model="$v.form.phone.$model"
            >
            <label for="wet-input-phone">
              {{ stringsAdditional.phone_plh }}
            </label>
          </div>
        </div>
        <div v-if="$v.form.phone.$error" class="small text-primary mt-n2 mb-3 mx-3">
          {{ stringsAdditional.error_phone }}
        </div>
        <h3 class="empty-ph is-size-6 mt-4 mt-sm-5">
          {{stringsAdditional.pay_i_title}}
        </h3>
        <div
          class="wet-input wet-input-with-label"
          :class="$v.form.iban.$error ? 'wet-input-error' : ''"
        >
          <input
            id="wet-input-iban"
            type="text"
            :placeholder="stringsAdditional.iban_plh"
            v-model.trim="$v.form.iban.$model"
          >
          <label for="wet-input-iban">
            {{ stringsAdditional.iban_plh }}
          </label>
        </div>
        <div v-if="$v.form.iban.$error" class="small text-primary mt-n2 mb-3 mx-3">
          {{ stringsAdditional.error_iban }}
        </div>
        <!-- REGISTRATION FORM -->
        <div v-if="showRegistrationForm">
          <h3 class="empty-ph is-size-6 mt-4 mt-sm-5">
            {{stringsAdditional.create_acc_title}}
          </h3>
          <div
            class="wet-input wet-input-with-label"
            :class="$v.form.email.$error ? 'wet-input-error' : ''"
          >
            <input
              id="wet-input-email"
              type="email"
              :placeholder="stringsAdditional.email_plh"
              v-model.trim="$v.form.email.$model"
            >
            <label for="wet-input-email">
              {{ stringsAdditional.email_plh }}
            </label>
          </div>
          <div
            v-if="$v.form.email.$error && $v.form.email.taken"
            class="small text-primary mt-n2 mb-3 mx-3"
          >
            {{ stringsAdditional.error_email }}
          </div>
          <div v-if="!$v.form.email.taken" class="small text-primary mt-n2 mb-3 mx-3">
            {{ strings.commonApp && strings.commonApp.error_email_already_used }}
          </div>
          <div
            class="wet-input wet-input-with-label has-addons"
            :class="$v.form.password.$error ? 'wet-input-error' : ''"
          >
            <input
              id="wet-input-password"
              :type="showPass ? 'text' : 'password'"
              :placeholder="stringsAdditional.pass_plh"
              v-model="$v.form.password.$model"
            >
            <label for="wet-input-password">
              {{ stringsAdditional.pass_plh }}
            </label>
            <button
              class="btn lh-1"
              @click="showPass = !showPass"
            >
              <wet-icon class="wet-icon text-gray" :type="showPass ? 'eye' : 'eye-strike'" />
            </button>
          </div>
          <div v-if="$v.form.password.$error" class="small text-primary mt-n2 mb-3 mx-3">
            {{ stringsAdditional.error_password }}
          </div>
          <div
            class="wet-input has-addons wet-input-with-label"
            :class="$v.form.passwordRetype.$error ? 'wet-input-error' : ''"
          >
            <input
              id="wet-input-password-retype"
              :type="showPassRetype ? 'text' : 'password'"
              :placeholder="stringsAdditional.retype_pass_plh"
              v-model="$v.form.passwordRetype.$model"
            >
            <label for="wet-input-password-retype">
              {{ stringsAdditional.retype_pass_plh }}
            </label>
            <button
              class="btn lh-1"
              @click="showPassRetype = !showPassRetype"
            >
              <wet-icon class="wet-icon text-gray" :type="showPassRetype ? 'eye' : 'eye-strike'" />
            </button>
          </div>
          <div v-if="$v.form.passwordRetype.$error" class="small text-primary mt-n2 mb-3 mx-3">
            {{ stringsAdditional.error_password_retype }}
          </div>
        </div>
        <label
          id="wet-additional-accept-chkbx-label"
          class="checkbox is-size-7 mb-2"
          for="wet-additional-accept-chkbx"
        >
          <strong class="empty-ph">
           {{ insurerId && stringsAdditional.accept_chkbx[insurerId] }}
           <a
             href="#sepa-modal"
             class="text-secondary"
             @click.prevent="$set(modalsOpened, 'sepa', true)"
            >
             {{ insurerId && stringsAdditional.accept_chkbx_link[insurerId] }}
           </a>
          </strong>
          <input
            type="checkbox"
            id="wet-additional-accept-chkbx"
            class=""
            v-model="$v.form.racceptChkbx.$model"
          >
          <span id="wet-additional-accept-chkbx-span"></span>
        </label>
        <div v-if="$v.form.racceptChkbx.$error" class="small text-primary mt-n2 mb-3 mx-3">
          {{ stringsAdditional.error_accept }}
        </div>
        <div class="mt-4 mt-sm-5 text-center text-sm-left">
          <strong>
            {{insurerId && stringsAdditional.personal_data_info[insurerId]}}
          </strong>
        </div>
      </div>
    </div>
    <wet-modal
      id="wet-modal-additional-sepa"
      :opened="modalsOpened.sepa"
      @close="$set(modalsOpened, 'sepa', false)"
      :content="`<div class='wet-note text-center'>
      ${insurerId && stringsAdditional.sepa_info[insurerId]}
      </div>`"
      :actions="[
        {
          title: strings.commonApp && strings.commonApp.ok,
          name: 'ok-additional-ok',
          event: () => $set(modalsOpened, 'sepa', false)
        }
      ]"
    >
    </wet-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import { email, required } from 'vuelidate/lib/validators';
import {
  companyName,
  firstname,
  iban,
  lastname,
  password,
  passwordRetype,
  phone,
} from '@/common/validations';
import countriesMap from '@/constants/countries';
import orderCheckIntersection from '@/mixins/orderCheckIntersection';
import partnerCodeMixin from '@/mixins/partner-code';
import placesText from '@/components/placesTextComponent.vue';
import LogService from '@/services/LogService';
import wpSection from '@/enums/wpSection';

export default {
  mixins: [common, orderCheckIntersection, partnerCodeMixin],
  props: {
    nextDisabled: Boolean,
  },
  components: {
    placesText,
    wetIcon: () => import('@/components/wetIcon.vue'),
    wpPicture: () => import('@/components/ui/wpPictureUi.vue'),
  },
  data() {
    return {
      initialAddress: undefined,
      userInfoEndpoint: '/api/user/info',
      userUpdateEndpoint: '/api/user/data/update',
      form: {
        iban: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        passwordRetype: '',
        registration: {},
        racceptChkbx: false,
        companyName: '',
      },
      formApi: {
        iban: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        passwordRetype: '',
        registration: {},
        racceptChkbx: false,
        companyName: '',
      },
      progress: [4, 5],
      wpAdditionalApi: '/wp-json/acf/v3/options/additional',
      addrShow: false,
      codeSelectOpen: false,
      agentSelectOpen: false,
      agents: [],
      showPass: false,
      showPassRetype: false,
      modalsOpened: {
        sepa: false,
      },
      registrationInvalid: false,
      registrationInvalidStreet: false,
      emailTaken: false,
      insurerId: null,
      partner: {},
      missingStreet: process.env.VUE_APP_MISSING_STREET,
      searchCountries: [process.env.VUE_APP_COUNTRY_CODE_SALE] || ['DE'],
    };
  },
  computed: {
    ...mapState(['strings', 'lang', 'order']),
    countries() {
      return Object.entries(countriesMap).map(([code, name]) => ({
        name: code.toUpperCase(),
        title: name[0].toUpperCase() + name.slice(1),
      }));
    },
    selectedAddress() {
      const street = this.form.locationStreet ? `${this.form.locationStreet} ` : '';
      const house = this.form.locationHouse ? `${this.form.locationHouse}; ` : '';
      const zip = this.form.locationZip ? `${this.form.locationZip} ` : '';
      const city = this.form.locationCity;
      return (street.includes(this.missingStreet) ? '' : street)
        + (street.includes(this.missingStreet) ? '' : house) + zip + city;
    },
    partnerLogo() {
      let logo = {};
      // get logo
      const { partnerCode } = this;
      if (partnerCode && partnerCode !== 'wetterheld') {
        const partner = this.strings
          .commonApp?.partners?.filter((p) => p.partner_code === partnerCode)[0];
        if (partner) logo = partner.logo;
      }
      return logo;
    },
    isNoStreet() {
      return !!this.formApi.locationStreet?.includes(this.missingStreet);
    },
    isTappedLocation() {
      return this.formApi.inputType === 'tap';
    },
    showRegistrationForm() {
      return ['3', '5'].includes(`${this.form.status}`);
    },
    registrationCountry() {
      return this.form.registration?.country || '';
    },
    stringsAdditional() {
      return this.strings.additional || {};
    },
  },
  watch: {
    formApi: {
      immediate: true,
      handler(v) {
        this.form = this.$lodash.cloneDeep(v);
      },
    },
    'form.email': {
      handler() {
        this.emailTaken = false;
      },
    },
  },
  validations() {
    return {
      form: {
        iban,
        firstname,
        lastname,
        email: {
          required,
          email,
          taken: () => !this.emailTaken,
        },
        phone,
        password,
        passwordRetype,
        racceptChkbx: {
          checked: (val) => val,
        },
        companyName,
      },
    };
  },
  async created() {
    await Promise.all([
      this.getWordPressStrings([wpSection.ADDITIONAL], wpSection.ADDITIONAL),
      this.$emit('update:nextDisabled', false),
      this.$root.$on('buttonNext', this.next),
      this.$root.$on('buttonBack', this.back),
      this.$store.commit('SET_APP_LAYOUT', { progress: this.progress }),
      this.apiUserInfo(),
    ]);
    if (!this.form.status || !['0', '1', '3', '4', '5'].includes(`${this.form.status}`)) {
      window.location = `/home/${this.$route.params?.lang || ''}`;
    }
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);
  },
  methods: {
    updateRegistrationInvalidStreet(newValue) {
      LogService.log('registrationInvalidStreetUpdate to value', newValue);
      this.registrationInvalidStreet = newValue;
    },
    updateHouseNo(houseNo) {
      this.form.registration.house = houseNo;
      LogService.log('changed houseNo', houseNo);
    },
    updateZip(zip) {
      this.form.registration.zip = zip;
      LogService.log('changed zip', zip);
    },
    updateCity(city) {
      this.form.registration.city = city;
      LogService.log('changed city', city);
    },
    async apiUserInfo() {
      // call User Info API
      await this.callApi(this.userInfoEndpoint)
        .then((res) => {
          this.prefillInputs(res.data);
        });
    },
    updateRegistrationAddress(registrationAddress) {
      this.form.registration = registrationAddress;
      LogService.log('changed registration address', registrationAddress);
    },
    /** prefill inputs from User info API data */
    prefillInputs(apiData) {
      /** check if phoneCode from api contained in this.phoneCodes */
      const phoneCode = this.phoneCodes.some((c) => c.code === apiData.profile?.phone_code)
        ? apiData.profile?.phone_code : this.phoneCodes[0].code;

      /** prefill formApi */
      this.formApi = {
        ...this.form,
        regAddrChkbx: false,
        firstname: apiData.profile?.firstname,
        lastname: apiData.profile?.lastname,
        phone: apiData.profile?.phone,
        phoneCode,
        companyName: apiData.profile?.company,
        locationAddress: apiData.order?.location?.address,
        locationCountryCode: apiData.order?.location?.country_code,
        locationCountry: apiData.order?.location?.country,
        locationPlaceId: apiData.order?.location?.place_id,
        locationCity: apiData.order?.location?.city,
        locationStreet: apiData.order?.location?.street,
        locationHouse: apiData.order?.location?.house,
        locationSH: [apiData.order?.location?.street, apiData.order?.location?.house].filter((el) => el && el.toString().length > 0).join(', '),
        locationZip: apiData.order?.location?.zip,
        registration: {
          address: apiData.profile?.registration_address?.address,
          street: apiData.profile?.registration_address?.address_line_1,
          house: apiData.profile?.registration_address?.address_line_2,
          city: apiData.profile?.registration_address?.address_line_city,
          zip: apiData.profile?.registration_address?.address_line_zip,
          country: apiData.order?.location?.country_code,
          country_code: apiData.order?.location?.country_code,
        },
        iban: apiData.payment?.iban?.toString(),
        email: apiData.user?.email,
        status: apiData?.user?.status?.toString(),
        agent: (apiData.profile?.agent_id
            && apiData?.agents.find((a) => a.id === apiData.profile?.agent_id))
          || apiData?.agents[0]
          || null,
        inputType: apiData.order?.location?.input_type,
        businessType: apiData.order?.business?.type,
      };

      /** insurer id */
      this.insurerId = apiData.insurer?.id;

      this.agents = apiData?.agents || [];

      this.partner = {
        id: apiData?.partner?.id,
        name: apiData?.partner?.name,
        show_name: Number(apiData?.partner?.show_name) > 0,
      };

      // if (this.formApi.registration.address
      //   && this.formApi.registration.address !== this.formApi.locationAddress) {
      //   this.$set(this.formApi, 'regAddrChkbx', true);
      //   this.initialAddress = this.formApi.registration.address;
      // }
      this.$store.commit('SET_USER_STATUS', this.formApi.status.toString());
    },
    updateRegistrationInvalid(newValue) {
      LogService.log('updateRegistrationInvalid to value', newValue);
      this.registrationInvalid = newValue;
    },
    validate() {
      this.$v.$touch();
      if (this.form.regAddrChkbx) {
        this.$refs.registration.$v.$touch();
        this.$refs.registration.$refs.places.$v.$touch();
      }
      LogService.log('validate registrationInvalid', this.registrationInvalid);
      if (this.$v.form.iban.$error
        || this.$v.form.firstname.$error
        || this.$v.form.companyName.$error
        || this.$v.form.lastname.$error
        || this.$v.form.phone.$error
        || this.$v.form.racceptChkbx.$error) {
        return true;
      }

      LogService.log('status', this.form.status);
      LogService.log('showRegistrationForm', this.showRegistrationForm);
      LogService.log('check any error', this.form.status !== '0' || this.showRegistrationForm);
      if (this.form.status !== '0' || this.showRegistrationForm) {
        LogService.log('$v.$anyError', this.$v.$anyError);
        if (this.$v.$anyError) {
          return true;
        }
      }

      LogService.log('regAddressChkbx', this.form.regAddrChkbx);
      if (this.form.regAddrChkbx) {
        this.$refs.registration.$v.$touch();
        this.$refs.registration.$refs.places.$v.$touch();
        LogService.log('places.invalid', this.$refs.registration.$refs.places.$v.$invalid,
          'regAddress.invalid', this.$refs.registration.$v.$invalid);
        if (this.$refs.registration.$refs.places.$v.$invalid
          || this.$refs.registration.$v.$invalid || this.registrationInvalidStreet) {
          return true;
        }
      }

      return false;
    },
    async next() {
      // handler for next layout button
      if (!this.validate()) {
        try {
          await this.updateUser();
          await this.saveBeforeLeave();
          this.$router.push({ name: 'buy', params: { ...this.$route.params } });
        } catch (error) {
          console.log(error);
        }
      }
    },
    back() {
      // handler for back layout button
      this.$router.push({ name: 'done', params: { ...this.$route.params } });
    },
    async updateUser() {
      // call api update user
      const data = {
        profile: {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          phone: this.form.phone,
          phone_code: this.form.phoneCode,
          company: this.form.companyName,
        },
        business_address: {
          address: this.form.locationAddress,
          address_line_1: this.form.locationStreet,
          address_line_2: this.form.locationHouse,
          address_line_zip: this.form.locationZip,
          address_line_city: this.form.locationCity,
          country_code: this.form.locationCountryCode,
          country: this.form.locationCountry,
          place_id: this.form.locationPlaceId,
        },
        payment: {
          iban: this.form.iban,
        },
      };

      data.registration_address = { ...data.business_address };

      if (this.showRegistrationForm) {
        data.user = {
          email: this.form.email,
          password: this.form.password,
        };
      }

      if (this.form.regAddrChkbx) {
        data.registration_address = {
          address: this.form.registration.address,
          address_line_1: this.form.registration.street,
          address_line_2: this.form.registration.house,
          address_line_zip: this.form.registration.zip,
          address_line_city: this.form.registration.city,
          country_code: this.form.locationCountryCode,
          country: this.form.locationCountry,
        };
      }

      if (!this.registrationInvalid) {
        data.registration_address.place_id = this.form.locationPlaceId;
      }

      if (this.form.agent) {
        data.profile.agent_id = this.form.agent.id;
      }

      await this.callApi({ url: this.userUpdateEndpoint, method: 'put', data })
        .catch((error) => {
          if (error.response?.data?.errors?.['user.email']?.[0] === 'That email address is already occupied') {
            this.emailTaken = true;
          } else console.log(error);
          throw new Error(error);
        });
    },
  },
};
</script>
